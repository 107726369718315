<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="CrossWordAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="game_cwObjList && game_cwObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="game_cwObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="CrossWordEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showGAME_CWDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGameCwAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <GameCwAdd :propOpenedInModal="true" @emitCloseGameCwAddModal="closeGameCwAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="CrossWordAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGameCwAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGameCwEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <GameCwEdit :propOpenedInModal="true" :propGAME_CWObj="GameCwEditObj" @emitCloseGameCwEditModal="closeGameCwEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="CrossWordEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGameCwEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGAME_CWDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGAME_CWDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="CrossWordDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { CrossWords } from "../../../FackApi/api/game_cw.js"
import GameCwAdd from "./CrosswordAdd"
import GameCwEdit from "./CrosswordEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "GAME_CWList",
  components: {
    GameCwAdd,
    GameCwEdit
  },
  data () {
    return {
      cvCardTitle: "CrossWords",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GAME_CW",
      cvAddModalHeader: "Add GAME_CW",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GAME_CW List Response",
      showModelGameCwAdd: false,
      showModelGameCwEdit: false,
      showModelGAME_CWDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "word", key: "word", class: "text-left align-text-top", sortable: true },
        { label: "description", key: "description", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      game_cwObjList: null,
      GameCwEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  mounted () {
    this.CrossWordList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * CrossWordList
     */
    async CrossWordList () {
      try {
        let gameCwListResp = await CrossWords.CrossWordList(this)
        if (gameCwListResp.resp_status) {
          this.game_cwObjList = gameCwListResp.resp_data.data
          this.totalRows = gameCwListResp.resp_data.count
        }
        else {
          this.toastMsg = gameCwListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at CrossWordList() and Exception:", err.message)
      }
    },
    /**
     * CrossWordAdd
     */
    CrossWordAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/game_cw_add")
        }
        else {
          this.showModelGameCwAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at CrossWordAdd() and Exception:", err.message)
      }
    },
    /**
     * CrossWordEdit
     */
    CrossWordEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/game_cw_edit/" + this.GameCwEditObj.game_cw_id)
        }
        else {
          this.GameCwEditObj = item
          this.showModelGameCwEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at CrossWordEdit() and Exception:", err.message)
      }
    },
    /**
     * showGAME_CWDeleteDialog
     */
    showGAME_CWDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGAME_CWDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGAME_CWDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * CrossWordDelete
     */
    async CrossWordDelete () {
      try {
        let gameCwDelResp = await CrossWords.CrossWordDelete(this, this.delObj.game_cw_id)
        await ApiResponse.responseMessageDisplay(this, gameCwDelResp)

        if (gameCwDelResp && !gameCwDelResp) {
          this.showModelGAME_CWDelete = false
          return false
        }

        let index = this.game_cwObjList.indexOf(this.delObj)
        this.game_cwObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGAME_CWDelete = false
      }
      catch (err) {
        console.error("Exception occurred at CrossWordDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGameCwAddModal
     */
    closeGameCwAddModal (gameCwAddData) {
      try {
        if (gameCwAddData) {
          if (this.game_cwObjList && this.game_cwObjList.length >= 1) {
            let gameCwObjLength = this.game_cwObjList.length
            let lastId = this.game_cwObjList[gameCwObjLength - 1]["id"]
            gameCwAddData.id = lastId + 1
          }
          else {
            this.game_cwObjList = []
            gameCwAddData.id = 11111
          }

          gameCwAddData.created_on = moment()
          this.game_cwObjList.unshift(gameCwAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGameCwAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeGameCwAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGameCwEditModal
     */
    closeGameCwEditModal () {
      try {
        this.showModelGameCwEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGameCwEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
